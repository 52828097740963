import { keyframes } from "styled-components"

export const purplePulse = keyframes`
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(98,69,233, 0.7);
    }

    70% {
      transform: scale(1);
      box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }

    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
  `
export const redPulse = keyframes`
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(245,72,127, 0.7);
    }

    70% {
      transform: scale(1);
      box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }

    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
  `
export const bluePulse = keyframes`
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(33,136,249, 0.7);
    }

    70% {
      transform: scale(1);
      box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }

    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
  `
export const yellowPulse = keyframes`
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(250,158,42, 0.7);
    }

    70% {
      transform: scale(1);
      box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }

    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
  `
