import React, { FC, useState, useEffect } from "react"
import Circles from "./Circles"
import styled from "styled-components"
import { Subtitle, Heading } from "../components"
import { data, Step } from "./data"
import { motion, AnimatePresence } from "framer-motion"
import { useTheme } from "../../styles/themes"

const MethodCycle: FC<MethodCycleProps> = () => {
  const { currentThemeName } = useTheme()
  const [activeItem, setActiveItem] = useState<number>(0)

  useEffect(() => {
    const incrementActiveItem = setTimeout(() => {
      if (activeItem === 3) {
        setActiveItem(0)
      } else {
        setActiveItem(activeItem + 1)
      }
    }, 2000)
    return () => clearTimeout(incrementActiveItem)
  }, [activeItem])

  const isDark = currentThemeName === "dark"

  const handleItemClick = (index: number) => {
    setActiveItem(index)
  }

  return (
    <>
      {data.map((entry: Step, index: number) => {
        return (
          <AnimatePresence key={index}>
            {activeItem === index ? (
              <TextContainer
                initial={{ opacity: 0, y: -60, x: -80 }}
                animate={{ opacity: 1, y: -35, x: -80 }}
                exit={{ opacity: 0, y: -10, x: -80 }}
              >
                <Subtitle color={entry.color} style={{ paddingBottom: "0px" }}>
                  {entry.subtitle}
                </Subtitle>
                <Heading
                  tag="h1"
                  style={{ fontSize: "40px", paddingBottom: "0px" }}
                >
                  {entry.title}
                </Heading>
              </TextContainer>
            ) : null}
          </AnimatePresence>
        )
      })}
      <OuterCircle isDark={isDark} />
      <Circles
        handleItemClick={handleItemClick}
        isDark={isDark}
        activeItem={activeItem}
        internalRotation={45}
      />
      <BigInnerCircle isDark={isDark} />
      <InnerCircle isDark={isDark} />
    </>
  )
}

type MethodCycleProps = {}

export default MethodCycle

const TextContainer = styled(motion.div)`
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 160px;
  transform: translate(-50%, -50%);
`

const OuterCircle = styled.div<{
  isDark: boolean
}>`
  position: absolute;
  width: 345px;
  height: 345px;
  background: ${props => props.theme.palette.backgroundColor};
  border: 12px solid
    ${props => (props.isDark ? "#393939" : props.theme.palette.foregroundColor)};
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50rem;
`

const BigInnerCircle = styled.div<{
  isDark: boolean
}>`
  position: absolute;
  width: 180px;
  height: 180px;
  background: ${props =>
    props.isDark ? "#2e2e2f" : props.theme.palette.foregroundColor};
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50rem;
`
const InnerCircle = styled.div<{
  isDark: boolean
}>`
  position: absolute;
  width: 200px;
  height: 200px;
  background: transparent;
  border: 1px solid
    ${props => (props.isDark ? "#393939" : props.theme.palette.foregroundColor)};
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50rem;
  z-index: 0;
`
