import React from "react"
import styled from "styled-components"
import {
  Column,
  Container,
  Heading,
  Paragraph,
  Spacer,
  Subtitle,
} from "../../components/components"
import FlexComponent from "../../components/Flex/Flex"
import MethodCycle from "../../components/MethodCycle/MethodCycle"

const Method = () => {
  return (
    <Container style={{ marginTop: "3rem" }}>
      <Flex>
        <StyledCol lg={7} style={{ justifyContent: "center" }}>
          <Subtitle color="purple">UNSERE METHODE</Subtitle>
          <Heading tag="h1">So sieht eine Zusammenarbeit mit uns aus</Heading>
          <Paragraph>
            Wir schaffen Transparenz und zeigen gerne, wie wir arbeiten. Mit
            unserer selbst entwickelten HTBR-Methode haben wir unsere eigenen
            Prozesse optimiert, um unseren Kunden hierdurch entscheidende
            Vorteile zu verschaffen. Durch eine strukturierte Übersicht unserer
            Prozesse geben wir Ihnen einen klaren Fahrplan an die Hand.
          </Paragraph>
          <Spacer gutter={32} />
        </StyledCol>

        <ImageWrapper>
          <ImageContainer>
            <MethodCycle />
          </ImageContainer>
        </ImageWrapper>
      </Flex>
    </Container>
  )
}

export default Method

const Flex = styled(FlexComponent)`
  flex-wrap: wrap;
  gap: 4rem;
  justify-content: center;
`

const StyledCol = styled(Column)``

const ImageContainer = styled.div`
  background-size: cover;
  position: relative;
`

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(30px);
  height: 380px;
  width: 380px;
  border-radius: 50%;
`
