import React from "react"

/** Div that can be used as a wrapper */
const Box: React.FC<{
  style?: React.CSSProperties
}> = ({ style, children }) => {
  return <div style={style}>{children}</div>
}

export default Box
