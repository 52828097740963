import React, { FC } from "react"
import { data, Step } from "./data"
import styled from "styled-components"
import { bluePulse, redPulse, purplePulse, yellowPulse } from "./animations"
const Circles: FC<CirclesProps> = ({
  activeItem,
  internalRotation,
  isDark,
  handleItemClick,
}) => {
  let rotation = internalRotation
  return (
    <>
      {data.map((item: Step, index) => {
        const circleSize = 110
        const radius = 170
        const angleIncrement = 360 / data.length
        rotation = rotation + angleIncrement
        return (
          <Circle
            onClick={() => handleItemClick(index)}
            isDark={isDark}
            color={item.color}
            key={index}
            activeItem={activeItem === index}
            transform={`rotate(${
              rotation * 1
            }deg) translateY(${radius}px) rotate(${rotation * -1}deg) `}
            circleSize={circleSize}
          >
            <Inner
              item={activeItem}
              activeItem={activeItem === index}
              color={item.color}
            >
              {item.icon()}
            </Inner>
          </Circle>
        )
      })}
    </>
  )
}

type CirclesProps = {
  activeItem: number
  internalRotation: number
  isDark: boolean
  handleItemClick: (index: number) => void
}

export default Circles

const getAnimation = (activeItem: number, isActive: boolean) => {
  if (isActive) {
    switch (activeItem) {
      case 0:
        return purplePulse
      case 1:
        return bluePulse
      case 2:
        return yellowPulse
      case 3:
        return redPulse
    }
  }
}

const Inner = styled.div<{
  color: "red" | "blue" | "yellow" | "purple"
  activeItem: boolean
  item: number
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px;
  border-radius: 50rem;
  transition: all 0.3s ease-in-out;
  animation: ${props => getAnimation(props.item, props.activeItem)} 2s infinite;
  background: ${props =>
    (props.color === "red" && props.theme.palette.red.light) ||
    (props.color === "blue" && props.theme.palette.blue.light) ||
    (props.color === "purple" && props.theme.palette.purple.light) ||
    (props.color === "yellow" && props.theme.palette.yellow.light)};
  &:hover {
    /* transform: ${props => (props.activeItem ? "scale(1.1)" : null)}; */
    transform: scale(1.1);
  }
`

const Circle = styled.div<{
  activeItem: boolean
  transform: string
  circleSize: number
  color: "red" | "blue" | "yellow" | "purple"
  isDark: boolean
}>`
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: ${p => p.transform};
  width: ${p => (p.circleSize ? `${p.circleSize}px` : null)};
  height: ${p => (p.circleSize ? `${p.circleSize}px` : null)};
  border-radius: ${p => (p.circleSize ? `${p.circleSize}px` : null)};
  display: flex;
  margin: ${p => (p.circleSize ? `-${p.circleSize / 2}px` : null)};
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;
  box-shadow: ${props =>
    props.isDark ? null : "rgba(0, 0, 0, 0.08) 0px 4px 12px"};
  cursor: pointer;
  background-color: ${props =>
    props.isDark ? props.theme.palette.foregroundColor : "#fff"};
  path {
    transform-origin: 50% 50%;
    transition: all 1s ease-in-out;
    fill: ${props =>
      (props.color === "red" && props.theme.palette.red.vibrant) ||
      (props.color === "blue" && props.theme.palette.blue.vibrant) ||
      (props.color === "purple" && props.theme.palette.purple.vibrant) ||
      (props.color === "yellow" && props.theme.palette.yellow.vibrant)};
  }
  &:hover {
  }
`
