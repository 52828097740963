import React from "react"
import { Icon } from "../components"
import styled from "styled-components"

export type Step = {
  subtitle: string
  title: string
  color: "red" | "blue" | "yellow" | "purple"
  icon: () => React.ReactElement
}

export const data: Step[] = [
  {
    subtitle: "SCHRITT 1",
    title: "HEAR",
    color: "purple",
    icon: () => <HearIcon />,
  },
  {
    subtitle: "SCHRITT 2",
    title: "THINK",
    color: "blue",

    icon: () => <ThinkIcon />,
  },
  {
    subtitle: "SCHRITT 3",
    title: "BUILD",
    color: "yellow",
    icon: () => <BuildIcon />,
  },
  {
    subtitle: "SCHRITT 4",
    title: "REPEAT",

    color: "red",
    icon: () => <RepeatIcon />,
  },
]

const HearIcon = styled(Icon.Hear)`
  width: 32px;
  height: 32px;
`
const ThinkIcon = styled(Icon.Think)`
  width: 32px;
  height: 32px;
`
const BuildIcon = styled(Icon.Build)`
  width: 32px;
  height: 32px;
`
const RepeatIcon = styled(Icon.Repeat)`
  width: 32px;
  height: 32px;
`
