import { GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image"
import React, { FC } from "react"
import styled from "styled-components"
import { Icon, Paragraph } from "../../components/components"
import { useTheme } from "../../styles/themes"

type Props = {
  authorName: string
  companyName: string
  logo: IGatsbyImageData
  text: string
}

const TestimonialCard: FC<Props> = ({
  text,
  authorName,
  companyName,
  logo,
}) => {
  const QuoteIcon = Icon.QuoteLeft

  const { currentThemeName } = useTheme()

  const image = getImage(logo!)!

  return (
    <OuterCardWrapper>
      <CardWrapper isDark={currentThemeName === "dark"}>
        <QuoteIconWrapper>
          <QuoteIcon height="3rem" />
        </QuoteIconWrapper>
        <Text>{text}</Text>
        <Source>
          <IconWrapper isDarkMode={currentThemeName === "dark"}>
            <ImageContainer
              objectFit="contain"
              alt={`Logo der ${companyName}`}
              image={image}
              loading="eager"
            />
          </IconWrapper>
          <Author>
            <AuthorName>{authorName}</AuthorName>
            <CompanyName>{companyName}</CompanyName>
          </Author>
        </Source>
      </CardWrapper>
    </OuterCardWrapper>
  )
}

export default React.memo(TestimonialCard)

const ImageContainer = styled(GatsbyImage)`
  height: 100%;
  filter: grayscale(1);
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  &:hover {
    filter: grayscale(0);
  }
`

const OuterCardWrapper = styled.div`
  padding: 1rem;
  max-width: 600px;
  margin: 0 auto;
`

const CardWrapper = styled.div<{
  isDark: boolean
}>`
  padding: 1rem;
  box-shadow: ${props =>
    props.isDark ? null : "rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;"};
  background: ${props => props.theme.palette.backgroundColor};
  border-radius: ${props => props.theme.borderRadius};
  user-select: none;
  width: 100%;
  height: 430px;
  cursor: grab;
  &:active {
    cursor: grabbing;
  }

  display: flex;
  flex-direction: column;

  path {
    fill: ${props => props.theme.palette.blue.vibrant};
  }
`

const QuoteIconWrapper = styled.div`
  display: flex;
  margin-bottom: 1rem;
`

const Text = styled(Paragraph)`
  font-size: 14px;
  padding: 0;
  overflow: auto;
  margin-bottom: auto;
`

const Source = styled.div`
  display: flex;
  overflow: hidden;
  flex-shrink: 0;
  margin-top: 1em;
  flex-wrap: wrap;
  gap: 1em;
`

const Author = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media only screen and (max-width: 500px) {
    width: 100%;
  }
`

const AuthorName = styled(Paragraph)`
  font-size: 1rem;
  padding: 0;
  white-space: nowrap;
`

const CompanyName = styled(Paragraph)`
  font-size: 1rem;
  padding: 0;
  font-weight: bold;
  white-space: nowrap;
`

const IconWrapper = styled.div<{ isDarkMode: boolean }>`
  border-radius: 8px;
  padding: 12px;
  display: flex;
  justify-content: center;
  align-items: center;

  ${p =>
    p.isDarkMode &&
    `
  background-color: #f8f9fb;
  `}

  margin-right: 1.5rem;
`
