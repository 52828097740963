import { graphql } from "gatsby"
import React, { Suspense } from "react"
import Box from "../components/Box"
import {
  ButtonLink,
  CardList,
  Container,
  Column,
  Row,
  Head,
  HeroSection,
  ImageLayer,
  Paragraph,
  SecondarySection,
  Spacer,
  Subtitle,
  Emphasized,
  Loader,
  Icon,
  Heading,
} from "../components/components"

import CompanyPortfolio from "../widgets/CompanyPortfolio/CompanyPortfolio"
import ContactForm from "../widgets/ContactForm/ContactForm"
import Method from "../widgets/Method/Method"
import { PageProps } from "gatsby"
import CustomerTestimonials from "../widgets/CutsomerTestimonials/CustomerTestimonials"
import styled from "styled-components"
const WebDevelopment = React.lazy(
  () => import("../components/Animations/WebDevelopment")
)

const HomeOverview = (props: PageProps<any>) => {
  //const data = props.data as any // TODO: add gatsby type
  const isSSR = typeof window === "undefined"
  return (
    <>
      <Head pagePath="/" />

      <HeroSection style={{ justifyContent: "space-between" }}>
        <Box style={{ zIndex: 1 }}>
          <Heading tag="h1">
            Individuelle <Emphasized>Softwarelösungen</Emphasized> <br />
            für Ihren Geschäftserfolg
          </Heading>
          <Paragraph maxWidth="450px">
            Wir entwickeln moderne und zukunftsfähige Lösungen – maßgeschneidert
            auf Ihre Bedürfnisse. Damit sich Software wieder Ihren
            Geschäftsprozessen anpasst und nicht andersrum.
          </Paragraph>
          <ButtonLink cta to={"#contact" as any}>
            Jetzt Beratungsgespräch sichern
          </ButtonLink>
        </Box>
        {!isSSR && (
          <Suspense fallback={<Loader />}>
            <WebDevelopment />
          </Suspense>
        )}

        <CompanyPortfolio />
      </HeroSection>

      <SecondarySection>
        <Row>
          <Container style={{ padding: 0 }}>
            <Column>
              <CardList
                entries={[
                  {
                    icon: () => <BenefitsIcon />,

                    title: "Chancen",
                    description:
                      "Erfahren Sie, warum wir Prozesse digitalisieren und wie individuelle Software als Regler für den Erfolg Ihres Unternehmens funktionieren kann. ",
                    color: "purple",
                    button: {
                      path: "/chancen",
                      text: "Mehr Erfahren",
                    },
                  },
                  {
                    icon: () => <IndustryIcon />,

                    title: "Branchen",
                    description:
                      "Aufgrund unserer Historie besitzen wir Expertise im Lösen von branchentypischen Problemen. Erfahren Sie jetzt, ob wir der richtige Partner für Sie sind.",
                    color: "red",
                    button: {
                      path: "/branchen",
                      text: "Mehr Erfahren",
                    },
                  },
                  {
                    icon: () => <ServicesIcon />,
                    title: "Leistungen",
                    description:
                      "Wir entwickeln maßgeschneiderte und zukunftsfähige Softwarelösungen, die Sie dabei unterstützen, Ihre Unternehmensziele zu erreichen.",
                    color: "blue",
                    button: {
                      path: "/leistungen",
                      text: "Mehr Erfahren",
                    },
                  },
                ]}
              />
            </Column>
          </Container>
        </Row>
        <Spacer gutter={80} />

        <Method />

        <Spacer gutter={80} />
        <CustomerTestimonials />
        <Spacer gutter={80} />
        <Container>
          <Row style={{ margin: 0 }}>
            <Column>
              <ImageLayer
                alt="Das Incoqnito Team sitzt gemeinsam in einem Besprächungsraum. Zwei Mitarbeiter stehen und präsentieren vor einem Whiteboard."
                color="purple"
                image={props.data.aboutUs.image}
              >
                <Subtitle color="purple">UNSER TEAM</Subtitle>
                <Heading tag="h2">
                  Hinter jedem Erfolg steht ein großartiges Team
                </Heading>
                <Paragraph>
                  Wir sind Experten für individuelle Softwareentwicklung, die
                  komplexe Herausforderungen mithilfe moderner Technologien
                  einfach lösen. Für unsere Kunden sind wir Partner bei der
                  Konzeption und Entwicklung innovativer Anwendungen, um die
                  Weichen für Ihren digitalen Erfolg zu stellen. Fachliche
                  Expertise kombiniert mit dem Ansatz, nachhaltigen Mehrwert zu
                  schaffen, ist unser Erfolgsrezept.
                </Paragraph>
                <ButtonLink arrowButton purple to="/ueber-uns">
                  Über uns
                </ButtonLink>
              </ImageLayer>
            </Column>
          </Row>
        </Container>
        <Spacer gutter={80} />
      </SecondarySection>
      <ContactForm />
    </>
  )
}

export default HomeOverview

const ServicesIcon = styled(Icon.Services)`
  width: 24px;
  height: 24px;
`
const IndustryIcon = styled(Icon.IndustrySectors)`
  width: 24px;
  height: 24px;
`
const BenefitsIcon = styled(Icon.Benefits)`
  width: 24px;
  height: 24px;
`

export const query = graphql`
  query {
    chances: file(relativePath: { eq: "stock/chances.jpg" }) {
      image: childImageSharp {
        gatsbyImageData(width: 800, layout: FULL_WIDTH)
      }
    }
    industries: file(relativePath: { eq: "stock/industries.jpg" }) {
      image: childImageSharp {
        gatsbyImageData(width: 800, layout: FULL_WIDTH)
      }
    }
    services: file(relativePath: { eq: "stock/services.jpg" }) {
      image: childImageSharp {
        gatsbyImageData(width: 800, layout: FULL_WIDTH)
      }
    }
    hexagon: file(relativePath: { eq: "stock/hexagons.png" }) {
      image: childImageSharp {
        gatsbyImageData(width: 650, height: 650, layout: CONSTRAINED)
      }
    }
    aboutUs: file(relativePath: { eq: "stock/aboutUs.jpg" }) {
      image: childImageSharp {
        gatsbyImageData(width: 800, height: 600)
      }
    }
  }
`
