import { motion } from "framer-motion"
import { graphql, useStaticQuery } from "gatsby"
import React, { FC, useEffect } from "react"
import styled from "styled-components"
// import Swiper core and required modules
import SwiperCore, { Autoplay } from "swiper"
// Import Swiper styles
import "swiper/css"
import "swiper/css/pagination"
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react"
import { Container, Heading } from "../../components/components"
import { useTheme } from "../../styles/themes"
import TestimonialCard from "./TestimonialCard"

import { AllContentfulCustomerTestimonialQuery } from "../../../generated/gatsby-graphql"

// install Swiper modules
SwiperCore.use([Autoplay])

type CustomerTestimonialsProps = {}

export type Entry = {
  companyName: string
  referencePerson: string
  description: string
}

const CustomerTestimonials: FC<CustomerTestimonialsProps> = () => {
  const { currentBreakpoint } = useTheme()

  const data = useStaticQuery<AllContentfulCustomerTestimonialQuery>(graphql`
    query allContentfulCustomerTestimonial {
      customerTestimonials: allContentfulCustomerTestimonial {
        nodes {
          updatedAt
          person
          customerReference {
            name
            logo {
              gatsbyImageData(height: 40, layout: FIXED, placeholder: BLURRED)
            }
          }
          text {
            text
          }
        }
      }
    }
  `)

  const [customerTestimonials, setCustomerTestimonials] = React.useState<
    typeof data["customerTestimonials"]["nodes"]
  >([])

  useEffect(() => {
    const customerTestimonialsSorted = data.customerTestimonials.nodes.sort(
      (a, b) => {
        const date = (input: string) => new Date(input).getTime()

        return date(b.updatedAt) - date(a.updatedAt) // newest update first
      }
    )

    setCustomerTestimonials(customerTestimonialsSorted)
  }, [data])

  const slidesPerView = () => {
    switch (currentBreakpoint.name) {
      default:
        return 3
      case "xxl":
      case "xl":
        return 2
      case "lg":
      case "md":
      case "sm":
        return 1
    }
  }

  return (
    <Wrapper>
      <Container>
        <Heading tag="h1">Was unsere Kunden über uns sagen</Heading>
      </Container>
      <List>
        <DragWrapper>
          <Swiper
            autoplay={{
              delay: 5000,
              disableOnInteraction: true,
              pauseOnMouseEnter: true,
            }}
            loop={true}
            slidesPerView={slidesPerView()}
            spaceBetween={20}
            lazy={false}
          >
            {customerTestimonials.map(entry => {
              return (
                <SwiperSlide key={entry.customerReference!.name!}>
                  <TestimonialCard
                    text={entry.text!.text!}
                    companyName={entry.customerReference!.name!}
                    authorName={entry!.person!}
                    logo={entry.customerReference!.logo!.gatsbyImageData}
                  />
                </SwiperSlide>
              )
            })}
          </Swiper>
        </DragWrapper>
      </List>
    </Wrapper>
  )
}

export default CustomerTestimonials

const Wrapper = styled.div`
  background-color: ${props => props.theme.palette.foregroundColor};
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 32px 0px;
`

// const Container = styled.div`
//   max-width: 1280px;
//   display: flex;
//   width: 100%;
//   padding: 32px 0;
// `
const List = styled(motion.div)`
  width: 100%;
  padding: 16px 0;
  overflow: hidden;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`
const DragWrapper = styled(motion.div)`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`
